<template>
  <div>
    <div
      :class="{ active: loading }"
      class="ui inverted dimmer"
    >
      <div class="ui loader" />
    </div>
    <div
      id="message"
      class="fullwidth"
    >
      <div
        v-if="error"
        class="ui negative message"
      >
        <p>
          <i
            class="close icon"
            aria-hidden="true"
          />
          {{ error }}
        </p>
      </div>
      <div
        v-if="success"
        class="ui positive message"
      >
        <i
          class="close icon"
          aria-hidden="true"
          @click="success = null"
        />
        <p>{{ success }}</p>
      </div>
    </div>
    <h1 v-if="project && feature_type">
      Éditer la symbologie du type de signalement "{{ feature_type.title }}" pour le
      projet "{{ project.title }}"
    </h1>
    <div class="fourteen wide column">
      <form
        id="form-symbology-edit"
        action=""
        method="post"
        enctype="multipart/form-data"
        class="ui form"
      >
        <SymbologySelector
          v-if="feature_type"
          class="default"
          :init-color="feature_type.color"
          :init-icon="feature_type.icon"
          :init-opacity="feature_type.opacity"
          :geom-type="feature_type.geom_type"
          @set="setDefaultStyle"
        />
        <div class="ui divider" />
        <div
          v-if="customizableFields.length > 0"
          class="field"
        >
          <label
            id="customfield-select-label"
            for="customfield-select"
          >
            Champ de personnalisation de la symbologie:
          </label>
          <div id="custom_types-dropdown">
            <Dropdown
              :options="customizableFields"
              :selected="selectedCustomfield"
              :selection.sync="selectedCustomfield"
            />
          </div>
        </div>
        <div
          v-if="selectedCustomfield"
          class="field"
        >
          <div 
            v-for="option of selectedFieldOptions"
            :key="option"
          >
            <SymbologySelector
              :id="option"
              :title="option"
              :init-color="feature_type.colors_style.value ?
                feature_type.colors_style.value.colors[option] ?
                  feature_type.colors_style.value.colors[option].value :
                  feature_type.colors_style.value.colors[option]
                : null
              "
              :init-icon="feature_type.colors_style.value ?
                feature_type.colors_style.value.icons[option] :
                null
              "
              :init-opacity="getOpacity(feature_type, option)"
              :geom-type="feature_type.geom_type"
              @set="setColorsStyle"
            />
          </div>
          <div class="ui divider" />
        </div>
        <button
          id="save-symbology"
          class="ui teal icon button margin-25"
          type="button"
          :disabled="!canSaveSymbology"
          @click="sendFeatureSymbology"
        >
          <i
            class="white save icon"
            aria-hidden="true"
          />
          Sauvegarder la symbologie du type de signalement
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { isEqual } from 'lodash';

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

import SymbologySelector from '@/components/FeatureType/SymbologySelector.vue';
import Dropdown from '@/components/Dropdown.vue';


export default {
  name: 'FeatureTypeSymbology',

  components: {
    SymbologySelector,
    Dropdown,
  },

  data() {
    return {
      loading: false,
      error: null,
      success: null,
      form: {
        color: '#000000',
        icon: 'circle',
        colors_style: {
          fields: [],
          colors: {},
          icons: {},
          opacities: {},
          custom_field_name: '',
          value: {
            colors: {},
            icons: {},
            opacities: {},
          }
        },
      },
      canSaveSymbology: false
    };
  },

  computed: {
    ...mapState('projects', [
      'project'
    ]),
    ...mapState('feature-type', [
      'customForms',
      'colorsStyleList'
    ]),
    ...mapGetters('feature-type', [
      'feature_type'
    ]),
    customizableFields() {
      if (this.feature_type) {
        let options = this.feature_type.customfield_set.filter(el => el.field_type === 'list' || el.field_type === 'char' || el.field_type === 'boolean');
        options = options.map((el) => {
          return { name: [el.name, this.getFieldLabel(el.field_type)], value: el };
        });
        return options;
      }
      return [];
    },
    selectedFieldOptions() {
      if (this.selectedCustomfield) {
        const customFieldSet = this.feature_type.customfield_set.find(el => el.name === this.selectedCustomfield);
        if (customFieldSet.options.length > 0) {
          return customFieldSet.options;
        } else if (customFieldSet.field_type === 'char') {
          return ['Vide', 'Non vide'];
        } else if (customFieldSet.field_type === 'boolean') {
          return ['Décoché', 'Coché'];
        }
      }
      return [];
    },
    selectedCustomfield: {
      get() {
        return this.form.colors_style.custom_field_name;
      },
      set(newValue) {
        if (newValue && newValue.value) {
          this.form.colors_style.custom_field_name = newValue.value.name;
        }
      }
    }
  },

  watch: {
    feature_type(newValue) {
      if (newValue) {
        // Init form
        this.form.color = JSON.parse(JSON.stringify(newValue.color));
        this.form.icon = JSON.parse(JSON.stringify(newValue.icon));
        this.form.colors_style = {
          ...this.form.colors_style,
          ...JSON.parse(JSON.stringify(newValue.colors_style))
        };
      }
    },
    form: {
      deep: true,
      handler(newValue) {
        if (isEqual(newValue, {
          color: this.feature_type.color, 
          icon: this.feature_type.icon,
          colors_style: this.feature_type.colors_style
        })) {
          this.canSaveSymbology = false;
        } else {
          this.canSaveSymbology = true;
        }
      }
    }
  },

  created() {
    if (!this.project) {
      this.GET_PROJECT(this.$route.params.slug);
      this.GET_PROJECT_INFO(this.$route.params.slug);
    }
    this.SET_CURRENT_FEATURE_TYPE_SLUG(this.$route.params.slug_type_signal);
    if (this.feature_type) {
      this.initForm();
    } else {
      this.loading = true;
      this.GET_PROJECT_FEATURE_TYPES(this.$route.params.slug)
        .then(() => {
          this.initForm();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  },

  methods: {
    ...mapMutations('feature-type', [
      'SET_CURRENT_FEATURE_TYPE_SLUG'
    ]),
    ...mapActions('feature-type', [
      'SEND_FEATURE_SYMBOLOGY',
      'GET_PROJECT_FEATURE_TYPES'
    ]),
    ...mapActions('projects', [
      'GET_PROJECT',
      'GET_PROJECT_INFO',
    ]),

    initForm() {
      this.form.color = JSON.parse(JSON.stringify(this.feature_type.color)); //? wouldn't be better to use lodash: https://medium.com/@pmzubar/why-json-parse-json-stringify-is-a-bad-practice-to-clone-an-object-in-javascript-b28ac5e36521
      this.form.icon = JSON.parse(JSON.stringify(this.feature_type.icon)); //? since the library is already imported ?
      this.form.colors_style = {
        ...this.form.colors_style,
        ...JSON.parse(JSON.stringify(this.feature_type.colors_style))
      };
      if (!this.form.colors_style.value['opacities']) { //* if the opacity values were never setted (would be better to find out why)
        this.form.colors_style.value['opacities'] = {};
      }
      if (this.feature_type.colors_style && Object.keys(this.feature_type.colors_style.colors).length > 0) {
        this.selectedCustomfield =
          this.feature_type.customfield_set.find(
            el => el.name === this.feature_type.colors_style.custom_field_name
          ).name;
      }
    },

    setDefaultStyle(e) {
      const { color, icon, opacity } = e.value;
      this.form.color = color.value;
      this.form.icon = icon;
      this.form.opacity = opacity;
    },

    setColorsStyle(e) {
      const { name, value } = e;
      const { color, icon, opacity } = value;
      this.form.colors_style.colors[name] = color;
      this.form.colors_style.icons[name] = icon;
      this.form.colors_style.opacities[name] = opacity;
      this.form.colors_style.value.colors[name] = color;
      this.form.colors_style.value.icons[name] = icon;
      this.form.colors_style.value.opacities[name] = opacity; //? why do we need to duplicate values ? for MVT ?
    },

    sendFeatureSymbology() {
      this.loading = true;
      this.SEND_FEATURE_SYMBOLOGY(this.form)
        .then(() => {
          this.GET_PROJECT_FEATURE_TYPES(this.$route.params.slug)
            .then(() => {
              this.loading = false;
              this.success =
                'La modification de la symbologie a été prise en compte. Vous allez être redirigé vers la page d\'accueil du projet.';
              setTimeout(() => {
                this.$router.push({
                  name: 'project_detail',
                  params: {
                    slug: this.$route.params.slug,
                  },
                });
              }, 1500);
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
        });
    },

    getOpacity(feature_type, optionName) {
      if (feature_type.colors_style.value && feature_type.colors_style.value.opacities) {
        return feature_type.colors_style.value.opacities[optionName];
      }
      return null;
    },

    getFieldLabel(fieldType) {
      switch (fieldType) {
      case 'list':
        return'Liste de valeurs';
      case 'char':
        return 'Chaîne de caractères';
      case 'boolean':
        return 'Booléen';
      }
    }
  }
};
</script>

<style lang="less" scoped>

h1 {
  margin-top: 1em;
}

form {
  text-align: left;
  #customfield-select-label {
    font-weight: 600;
    font-size: 1.1em;
  }

  #custom_types-dropdown > .dropdown {
    width: 50%;
  }

}

</style>
