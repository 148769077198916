<template>
  <div>
    <div class="three fields">
      <h4 :class="['field', {'row-title' : isDefault}]">
        {{ title }}
      </h4>
      <div class="required inline field">
        <label :for="form.color.id_for_label">{{ form.color.label }}</label>
        <input
          :id="form.color.id_for_label"
          v-model.lazy="form.color.value"
          type="color"
          required
          :name="form.color.html_name"
        >
      </div>

      <div v-if="geomType === 'polygon' || geomType === 'multipolygon'">
        <label>Opacité &nbsp;<span>(%)</span></label>
        <div class="range-container">
          <input
            id="opacity"
            v-model="form.opacity"
            type="range"
            min="0"
            max="1"
            step="0.01"
          >
          <output class="range-output-bubble">
            {{ getOpacity(form.opacity) }}
          </output>
        </div>
      </div>
    </div>

    <div
      v-if="isIconPickerModalOpen"
      ref="iconsPickerModal"
      class="ui dimmer modal transition active"
    >
      <div class="header">
        Sélectionnez le symbole pour ce type de signalement :
      </div>
      <div class="scrolling content">
        <div
          v-for="icon of iconsNamesList"
          :key="icon"
          :class="['icon-container', { active: form.icon === icon }]"
          @click="selectIcon(icon)"
        >
          <i
            :class="`icon alt fas fa-${icon}`"
            aria-hidden="true"
          />
        </div>
      </div>
      <div class="actions">
        <div
          class="ui cancel button"
          @click="isIconPickerModalOpen = false;"
        >
          Fermer
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import faIconsNames from '@/assets/icons/fa-icons.js';

export default {
  name: 'SymbologySelector',

  props: {
    title: {
      type: String,
      default: 'Symbologie par défault :'
    },
    initColor: {
      type: String,
      default: '#000000'
    },
    initIcon: {
      type: String,
      default: 'circle'
    },
    initOpacity: {
      type: [String, Number],
      default: '0.5'
    },
    geomType: {
      type: String,
      default: 'Point'
    }
  },

  data() {
    return {
      isIconPickerModalOpen: false,
      iconsNamesList: faIconsNames,
      form: {
        icon: 'circle',
        color: {
          id_for_label: 'couleur',
          label: 'Couleur',
          field: {
            max_length: 128, // ! Vérifier la valeur dans django
          },
          html_name: 'couleur',
          value: '#000000',
        },
        opacity: '0.5',
      }
    };
  },

  computed: {
    isDefault() {
      return this.title === 'Symbologie par défault :';
    }
  },

  watch: {
    form: {
      deep: true,
      handler(newValue) {
        this.$emit('set', {
          name: this.isDefault ? null : this.title,
          value: newValue
        });
      }
    }
  },

  created() {
    this.form.color.value = this.initColor;
    if (this.initIcon) {
      this.form.icon = this.initIcon;
    }
    if (this.initOpacity) {
      this.form.opacity = this.initOpacity;
    }
    this.$emit('set', {
      name: this.title,
      value: this.form
    });
  },

  methods: {
    openIconSelectionModal() {
      this.isIconPickerModalOpen = true;
    },

    selectIcon(icon) {
      this.form.icon = icon;
    },

    getOpacity(opacity) {
      return Math.round(parseFloat(opacity) * 100);
    },
  }
};
</script>

<style lang="less" scoped>

.fields {
  align-items: center;
  justify-content: space-between;
  margin-top: 3em !important;
}

.row-title {
  display: inline;
  font-size: 1.4em;
  font-weight: normal;
  width: 33%;
  text-align: left;
  margin-left: 0.5em;
}

.default {
  margin-bottom: 2rem;
}

#couleur {
  width: 66%;
  cursor: pointer;
  box-shadow: 0 0 1px 1px rgb(189, 189, 189);
}

.picker-button {
  height: 50px;
  width: 50px;
  border-radius: 3px;
  box-shadow: 0 0 2px 1px rgb(131, 131, 131);
  .icon.alt {
    width: 30px;
    height: 30px;
  }
}
.picker-button:hover {
  box-shadow: 0 0 2px 1px rgb(165, 165, 165);
}

.modal {
  height: fit-content;
  .content {
    display: flex;
    flex-flow: row wrap;
    .icon-container {
      padding: 7px;
      .icon.alt {
        color: rgb(75, 75, 75);
        width: 30px;
        height: 30px;
      }
    }
    .icon-container:hover {
      cursor: pointer;
      background-color: rgba(130, 216, 219, 0.589);
    }
    .icon-container.active {
      background-color: rgba(130, 216, 219, 0.589);
    }
  }
}

</style>
